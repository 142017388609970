// \src\i18n\index.ts
import { createI18n } from "vue-i18n";
// 语言包
import zhCn from "./lang/zh-cn";
import en from "./lang/en";
import tr from "./lang/tr";
import ru from "./lang/ru";
 
const i18n = createI18n({

  globalInjection: true, //全局使用$t方法
  legacy: false,
  locale: sessionStorage.getItem("localeLang") || "zhCn",
  messages: {
    zhCn,
    en,
    tr,
    ru
  },
});
 
export default i18n;