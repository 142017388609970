// 俄语语言包：\src\i18n\lang\en.ts
export default {
  welcome: {
    name: 'Добро пожаловать',
  },
  title: {
    name: 'Управление глобальными продажами игр',
  },
  menu: {
    1000: 'Управление изображениями',
    1001: 'Управление продукцией',
    1002: 'Управление запасами',
    1003: 'Управление ценами',
    1004: 'Торнадо покупает',
    1005: 'Торнадо для пополнения',
    1006: 'Выверка данных',
    1007: 'Знание задачи карты',
    1008: 'Знание результатов карты',
    1009: 'Список продуктов',
    1010: 'Продукция рекомендована',
    1011: 'Список запасов',
    1012: 'Товарно - материальные наименования',
    1013: 'Список цен',
    1014: 'Рекорды изменения цен',
    1015: 'Управление счетом',
    1016: 'Купить задание',
    1017: 'Записи о покупке',
    1018: 'Покупатель пополнения',
    1019: 'Подробности кошелька',
    1020: 'Задачи пополнения',
    1021: 'Счета пополнения',
    1022: 'Код пополнения',
    1023: 'Статистика сверки',
    1024: 'Системные инструменты',
    1025: 'Преобразование обменных курсов',
    1026: 'Управление конфигурацией',
    1027: 'Управление закупками',
    1028: 'Управление поставщиками',
    1029: 'Управление связями',
    1030: 'Внутреннее управление грузами',
    1031: 'Журнал закупок',
    1032: 'Заказы на закупку',
    1033: 'Элементы учета',
    1034: 'Бухгалтерский поток',
    1035: 'Счет - фактурный снимок',
    1036: 'Счетная ведомость',
  },
  platformType: {
    1: 'Торнадо Платформа',
    2: 'Оптовая платформа',
    3: 'Платформа C2C',
    4: 'Большой фон',
  },
  userInfo: {
    myWorkOrder: {
      feedback: 'Моя обратная связь',
      creatOrder: 'Создать рабочий лист',
      accountIssue: 'Проблемы со счетами',
      digitalProductIssue: 'Проблемы цифровых продуктов',
      SelfOperatedProductIssue: 'Проблемы с самостоятельной продукцией',
      C2CrechargeIssue: 'Проблема пополнения c2c',
      OtherIssues: 'Другие вопросы',
      Unread: 'Нечитанные',
      Processing: 'В процессе обработки',
      shelve: 'Отложить',
      Completed: 'Обработка завершена',
      rejectHandle: 'Отказ обрабатывать',
      Filter: 'Скреиватель',
      TicketType: 'Тип рабочего листа',
      Select: 'Пожалуйста, выберите',
      TicketStatus: 'Статус рабочего листа',
      createTime: 'Время создания',
      ContactCustomerService: 'Связаться с клиентом',
      CustomerService: 'Обслуживание клиентов',
      AverageResponseTime: 'Общее время ответа',
      WorkingDays: 'Один рабочий день',
      EndConversation: 'Конец сеанса',
      workOrder: 'Рабочий лист',
      ProductName: 'Название продукта',
      Order: 'Заказы',
      OrderNumber: 'Номер заказа',
      EmailAccount: 'Номер почтового ящика',
      ProblemDescription: 'Описание проблемы',
      placeholder:
        'Пожалуйста, опишите проблему настолько подробно, насколько это возможно',
      description:
        'Предоставление приложений позволяет лучше интерпретировать вашу проблему (загружать до 5 файлов)',
      PleaseInput: 'Пожалуйста, введите',
      upload: 'Загрузить файл',
      startTime: 'Время начала',
      endTime: 'Время окончания',
      customName: 'Имя клиента',
    },
  },
  common: {
    batch: 'Партии ',
    lotSize: 'Серия ',
    download: 'Скачать ',
    please: 'Пожалуйста ',
    input: 'Введите ',
    choose: 'Выбор ',
    email: ' Почтовый ящик',
    sendEmail: 'Нам нужно отправить заказ в этот',
    valid: 'Эффективно ',
    gameUp: 'Игра Прямая зарядка',
    manage: ' Управление ',
    history: 'История ',
    order: ' Заказы ',
    productName: 'Товарные наименования',
    account: ' Номер счета ',
    number: ' Номер',
    tracking: 'Логистика ',
    note: ' Единый ',
    status: 'Статус',
    purchaseBatch: 'Закупочная партия ',
    warehouse: ' Склад',
    date: 'Даты',
    searchResult:
      'Общее количество продуктов в текущих условиях отбора составляет ',
    allChoose: 'Полностью избранный',
    batchConfirmReceipt: 'Партийное подтверждение получения ',
    product: ' Продукты',
    createBy: 'Создатель',
    quantity: ' Количество ',
    delivered: 'Доставлено',
    waitProcess: 'Подлежит обработке',
    trackShipInfo: 'Отслеживание информации о накладной',
    confirmReceipt: 'Подтверждение получения ',
    total: ' Всего',
    cost: ' Стоимость ',
    confimProducts: 'Вы подтвердите получение n единиц продукции',
    confirm: 'Подтверждаю ',
    cancel: 'Отменить ',
    noShipInfo: 'Нет информации о доставке',
    shipped: 'Доставлено ',
    inTransit: 'Проходит транзит',
    transferring: 'В перевалке ',
    transfer: 'Транспортировка ',
    deliverying: 'Проводится доставка',
    cancelled: 'Отменено ',
    returnProcess: 'Возврат производится ',
    sendBack:  "Готовы вернуть",
    sending:  "Вернуться в Китай",
    sended:  "Уже возвращены",
    today: 'Сегодня',
    tomorrow: 'Завтра',
    expected: 'Прогнозируется ',
    deliver: 'Доставка ',
    giftDispatched: 'Подарки отправлены',
    completed: 'Завершено ',
    refunded: 'Возврат произведен',
    unknownStatus: 'Неизвестное состояние',
    giftRedeemed: 'Подарки обменяны',
    noDelivery: 'Не доставлено',
    management: 'Управление',
    region: 'Районы',
    refresh: 'Обновить ',
    noNeed: 'Не нужно ',
    wait: 'Ожидание ',
    refreshing: 'Обновляется',
    success: ' Успех',
    fail: ' Неудача',
    recharge: 'Поднаполнение',
    noRecharge: 'Не пополненный',
    recharging: 'В пополнении',
    startTime: 'Время начала',
    endTime: 'Время окончания',
    password: ' Пароль',
    pwdReset: 'Пароль сбросить',
    resetPwd: 'Сбросить пароль',
    resettingPwd: 'Сбросить пароль',
    balanceMinimum: 'Минимальное сальдо',
    balanceMaximum: 'Максимальное значение остатка',
    minimum: 'Минимальная ',
    maximum: 'Максимум ',
    purchaseG: 'Купить',
    goods: ' Товары',
    haocai: 'Расходные материалы',
    fee: "Стоимость ",
    menu1: "Управление расходными материалами",
    finally: "Наконец ",
    delInfo: "Вы уверены, что хотите удалить этот контент?",
    all: 'Все ',
    games: ' Игры',
    totalBalance: ' Общий остаток',
    averageRate: 'Средний обменный курс',
    noGet: 'Не получены ',
    search: 'Запрос ',
    reset: 'Сбросить ',
    placeOrder: ' Выписать заказ ',
    add: 'Добавлено ',
    export: 'Импортировать ',
    supplementValue: 'Дополнительное значение',
    balanceAndOrder: 'Сальдо и заказ',
    start: 'Запуск ',
    stop: 'Стоп ',
    task: ' Миссия',
    current: 'В настоящее время',
    info: ' Информация',
    balance: ' Сальдо',
    normal: 'Нормально ',
    notRechage: 'Не пополненный',
    remarks: 'Примечания',
    creation: 'Создание ',
    operation: 'Операция ',
    noEmail: 'Аккаунт не добавляет вспомогательный почтовый ящик в систему',
    creat: 'Создание ',
    edit: 'Редактировать ',
    viewOrder: 'Просмотреть заказ ',
    viewPwd: 'Просмотреть пароль ',
    login: 'Войти ',
    title: ' Название',
    template: ' Шаблоны',
    type: 'Тип',
    host: 'Хост',
    handle: 'Ручка',
    name: 'Имя',
    address: ' Адрес',
    isAddress: 'Является ли адрес по умолчанию',
    whether: 'Да нет ',
    has: "Есть",
    yes: 'Да',
    no: 'Нет',
    sufficient: 'Достаточно ',
    update: 'Обновление ',
    delete: 'Удалить ',
    updater: 'Обновить человека',
    time: 'Время',
    total1: 'Всего',
    item: 'Статья',
    page: 'Страницы',
    state: 'Штат',
    city: 'Город',
    district: 'Район',
    postalCode: 'Почтовый индекс',
    lastName: 'Фамилия',
    firstName: 'Имя',
    mobile: 'Номер мобильного телефона',
    purchase: ' Закупки',
    purchasing: 'В процессе закупок',
    waitPurchase: 'Предстоит закупка',
    endPurchase: 'Завершение покупки',
    unused: 'Не использован ',
    use: 'Использовать ',
    agent: 'Агент ',
    restart: 'Перезагрузка ',
    pause: 'Приостановление ',
    end: 'Конец ',
    mainBackend: 'Большой фон ',
    purchased: 'Приобретено ',
    error: ' Ошибка',
    orderPlacer: 'Следующий одиночник',
    oneMonth: 'Январь',
    twoMonth: 'Февраль',
    threeMonth: 'Март',
    fourMonth: 'Апрель',
    fiveMonth: 'Май',
    sixMonth: 'Июнь',
    sevenMonth: 'Июль',
    eightMonth: 'Август',
    nineMonth: 'Сентябрь',
    tenMonth: 'Октябрь',
    month1: 'Ноябрь',
    month2: 'Декабрь',
    year: 'Годы',
    operatingData: 'Эксплуатационные данные',
    rechargeAmount: 'Сумма пополнения',
    recharger: 'Покупатель пополнения',
    purchaseCost: 'Затраты на закупку возвращенной продукции',
    otherCost: 'Прочие затраты возвращенной продукции',
    paidAmount: ' Возврат',
    money: ' Сумма',
    productProfit: 'Вернутая прибыль от продукции',
    purchaseManage: 'Управление партиями закупок',
    receivedStore: 'Уже складировано',
    unitCost: 'Стоимость отдельных продуктов',
    receiving: 'Получение ',
    allReceived: 'Все ли в хранилище',
    store: 'Склад ',
    details: ' Детали',
    noData: 'Нет данных',
    brand: 'Марка ',
    hasStock: 'Есть ли запасы',
    view: 'Смотреть ',
    delivery: 'Доставка ',
    outGoging: 'Развернуть ',
    inComing: 'Переведено ',
    equivalentValue: 'Эквивалентность',
    appoint: 'Назначено ',
    storage: 'Хранить ',
    summary: 'Резюме ',
    inventory: 'Запасы ',
    totalPrice: 'Общая цена',
    unitPrice: 'Цена за единицу',
    equalPrice: 'Эквивалентность',
    discount: 'Скидка',
    averageInventory: 'Запасы в среднем',
    inTransitInventory: 'В пути ',
    others: 'Другие ',
    inbound: 'Склад ',
    original: 'Оригинал ',
    final: 'В конце концов ',
    average: ' В среднем',
    sold: 'Продано ',
    transferOut: 'Развернуть ',
    external: 'Внешний ',
    sales: ' Продается',
    profit: ' Прибыль',
    price: ' Цены',
    amount: ' Всего',
    receivable: 'К получению ',
    received: 'Возврат произведен',
    notComplete: 'Незаконченный ',
    payment: ' Поступления',
    commision: ' отчисление',
    outbound: 'Выход из склада',
    salesStaff: 'Продам сотрудников',
    payComplete: 'Завершен ли возврат',
    complete: 'Завершено ',
    sellingPrice: 'Цена продажи',
    returnPay: 'Возврат ',
    returnComplete: 'Возврат завершен ',
    ifreturnComplete:
      'Общая сумма текущей дебиторской задолженности, определяет, что возврат завершен?',
    confirmReturn: 'Определить, что возврат завершен',
    additional: 'Добавить ',
    currency: 'Валюта ',
    corresponding: 'Соответствие ',
    column: ' Колонка',
    bill: ' Счет',
    bookkeeping: 'Учет ',
    way: ' Способ',
    estimate: 'Прогноз ',
    modify: 'Изменить ',
    recovery: 'Восстановление ',
    listing: 'Верхняя полка ',
    delisting: ' Нижняя полка',
    standards: ' Спецификация',
    newGoods: ' Новый товар ',
    growthPercentage: 'Рост в процентах ',
    as: 'Для ',
    default: 'По умолчанию ',
    system: ' Система',
    customer: ' Клиент',
    contact: 'Связь ',
    company: ' Компания',
    unable: 'Невозможно ',
    transient: 'Временно ',
    locked: 'Заперто ',
    timeout: 'Время ожидания',
    sync: 'Синхронизация ',
    manual: 'Ручной ',
    new: ' Новые ',
    upload: 'Загрузить ',
    file: ' Документы',
    afresh: 'Вновь ',
    checkWarning:
      'Есть контент, который не заполнен полностью, пожалуйста, проверьте',
    statement: ' Поток воды',
    plateform:  'Платформа',
    claim: 'Признани е',
    binance: ' Монета Энн',
    virtual: ' Виртуальный ',
    area: ' Регион',
    image: 'Фото',
    close: 'Закрыть ',
    save: 'Сохранить ',
    and: 'И',
    canOnly: 'Только ',
    fileContent:
      'Можно выбрать только один файл, заново выбрать для покрытия предыдущего файла!',
    thisTime: 'В этот раз ',
    remaining: 'Остаток ',
    increase: 'Увеличить ',
    expenses: 'Расходы ',
    single: 'Единовые ',
    already: 'Уже ',
    outbound1: 'Из хранилища ',
    selected: 'Выбраны ',
    list: ' Список',
    staff: ' Работники',
    get: 'Приобретение ',
    lessThan: 'Должно быть меньше ',
    pwdError:
      'Пароль должен состоять из прописных букв и цифр, длиной от 8 до 15 бит',
    tryLater: 'Попробуйте позже',
    items: ' Один',
    import: 'Импортировать ',
    direct: 'Непосредственно ',
    youwill: 'Вы будете ',
    specifics: ' Подробности',
    num: '  Числа',
    aswell: 'И',
    tempImport:
      'Вы можете выбрать, чтобы скачать шаблон ниже, а затем заполнить свою учетную запись и пароль, чтобы облегчить массовый импорт',
    assistant: 'Вспомогательный ',
    exchangeRate: ' Обменный курс',
    logining: 'В процессе входа',
    retry: 'Повторно попробуйте ',
    scope:  'Сфера охвата ',
    press: 'Нажмите ',
    user: ' Пользователь',
    condition: '  Условия',
    revoke: 'Вы уверены, что хотите отменить это ',
    restartErr: 'Невозможно перезагрузить после окончания',
    addOne: "Добавьте хотя бы один",
    noOver: "Не может быть больше 100",
    accountRecharge: "Эти учетные записи пополняются:",
    riskControl: "Уже под контролем ветра",
    chooseMore: "Выберите хотя бы один из следующих шаблонов адресов",
    return: "Возвращение ",
    last: "Предыдущий ",
    next: "Следующий ",
    step: "Шаг",
    result: "Результат",
    noDelete: "Последняя строка больше не может быть удалена",
    detail: "Описание или",
    submit: "Представлено",
    entity: "Физические",
    cancelOrder: "Отзыв ордера",
    bind: " Объединение ",
    daxinBank: "Большой новый банк",
    start1: 'Время ',
    diff: "Несоответствия",
    de: "A ",
    create: "Создание",
    onOver: "Не может превышать",
    delInfo: "Вы уверены, что хотите удалить этот контент?",
  },
}
