// 英文语言包：\src\i18n\lang\en.ts
export default {
  welcome: {
    name: "welcome",
  },
  title: {
    name: "FutureSpacegamer Sale Manager",
  },
  menu: {
    1000: "Picture Manage",
    1001: "Product Manage",
    1002: "Stock Manage",
    1003: "Price Manage",
    1004: "Razer Buy",
    1005: "Razer Recharge",
    1006: "Billing Statistics",
    1007: "Identify Images Task",
    1008: "Identify Images Result",
    1009: "Product List",
    1010: "Product Recommendations",
    1011: "Stock List",
    1012: "Stock Detailed",
    1013: "Price List",
    1014: "Change Price Records",
    1015: "Account manage",
    1016: "Purchase Task",
    1017: "Purchase Records",
    1018: "Recharger",
    1019: "Wallet Details",
    1020: "Recharge Task",
    1021: "Recharge Account",
    1022: "Recharge Code",
    1023: "Reconciliation Statistics",
    1024: "System Tool",
    1025: "Exchange Rate",
    1026: "Configuration Manage",
    1027: "Purchase Manage",
    1028: "Supplier Manage",
    1029: "Association Manage",
    1030: "Inside Goods Manage",
    1031: "Purchase Logs",
    1032: "Purchase Orders",
    1033: "Bill Entry",
    1034: "Bill Flow",
    1035: "Bill Snapshot",
    1036: "Bill Report",
  },
  platformType: {
    1: "雷蛇平台",
    2: "批发平台",
    3: "c2c平台",
    4: "大后台",
  },
  userInfo: {
    myWorkOrder: {
      feedback: "My Feedback",
      creatOrder: "Create",
      accountIssue: "Account Issue",
      digitalProductIssue: "Digital Product Issue",
      SelfOperatedProductIssue: "Self-Operated Product Issue",
      C2CRechargeIssue: "C2C Recharge Issue",
      OtherIssues: "Other Issues",
      Unread: "Unread",
      Processing: "Processing",
      shelve: "shelve",
      Completed: "Completed",
      rejectHandle: "reject Handle",
      Filter: "Filter",
      TicketType: "Ticket Type",
      Select: "Select",
      TicketStatus: "Ticket Status",
      createTime: "Create Time",
      ContactCustomerService: "Contact Customer Service",
      CustomerService: "Customer Service",
      AverageResponseTime: "Average Response Time",
      WorkingDays: "Working Days",
      EndConversation: "End Conversation",
      workOrder: "Work Order",
      ProductName: "Product Name",
      Order: " Order",
      OrderNumber: "Order Number",
      EmailAccount: "Email Account",
      ProblemDescription: "Problem Description",
      placeholder:
        "Please provide a detailed description of the issue you encountered",
      description:
        "Attaching files can better illustrate your problem (maximum 5 files can be uploaded)",
      PleaseInput: "Please Input",
      upload: "Upload File",
      startTime: "start time",
      endTime: "end time",
      customName: "Customer Name",
    },
  },
  common: {
    batch: "Batch ",
    lotSize: " Quantity ",
    download: "Download ",
    please: "Please ",
    input: "Input ",
    choose: "Choose ",
    email: " Email ",
    sendEmail: "We need to send the order to this location",
    valid: " Effective",
    gameUp: "Game Direct Charging",
    manage: " Management",
    history: "History ",
    order: " Order ",
    productName: "Product Name ",
    account: " Account ",
    number: " Number",
    tracking: " Logistics Order ",
    note: " Note ",
    status: " Status",
    purchaseBatch: "Procurement Batch ",
    warehouse: " Warehouse ",
    date: "Date ",
    searchResult: "The total number of products under the current filtering criteria is ",
    allChoose: "Select All",
    batchConfirmReceipt: "Batch confirmation of receipt",
    product: " Products ",
    createBy: "The creator",
    quantity: " Quantity ",
    delivered: "Delivered",
    waitProcess: "Pending",
    trackShipInfo: "Track waybill information",
    confirmReceipt: "Confirm receipt of goods ",
    total: " Total",
    cost: " Cost ",
    confimProducts: "You will confirm receipt of n quantities of products",
    confirm: "Confirm ",
    cancel: "Cancel ",
    noShipInfo: "There is currently no shipping information available",
    shipped: "Shipped ",
    inTransit: "In transit",
    transferring: "In transit",
    transfer: "transfer ",
    deliverying: "Delivery in progress",
    cancelled: "Cancelled",
    returnProcess: "Returning",
    sendBack: "Prepare to send it back",
    sending: "Returning to China",
    sended: "Returned ",
    today: "Today ",
    tomorrow: "Tomorrow ",
    expected: "Expected ",
    deliver: "Deliver ",
    giftDispatched: "The gift has been sent out",
    completed: "Completed ",
    refunded: "Refund received",
    unknownStatus: "Unknown state ",
    giftRedeemed: "The gift has been exchanged",
    noDelivery: "Not delivered",
    management: "Management",
    region: "Region",
    refresh: "Refresh ",
    noNeed: "No need to ",
    wait: "Waiting",
    refreshing: "Refreshing",
    success: "Success",
    fail: "Failed",
    recharge: "Recharge ",
    recharger: "Recharger",
    noRecharge: "Not recharged",
    recharging: "In the process of recharging",
    startTime: "Starting time ",
    endTime: "End time ",
    password: " Password",
    pwdReset: "Password Reset",
    resetPwd: "Reset password ",
    resettingPwd: "Resetting password ",
    balanceMinimum: "Minimum balance value ",
    balanceMaximum: "Maximum balance value ",
    minimum: "minimum ",
    maximum: "maximum ",
    purchaseG: "Purchase ",
    goods: " Goods ",
    haocai: 'Consumables ',
    fee: 'Expense',
    menu1: 'Consumables Cost Management',
    finally: 'Finally ',
    delInfo: "Are you sure to delete this?",
    all: "All ",
    games: "Game",
    totalBalance: "Total balance",
    averageRate: "The average exchange rate",
    noGet: "Without obtained ",
    search: "Search",
    reset: "Reset ",
    placeOrder: " Order",
    add: "New ",
    export: "Import ",
    supplementValue: "Supplementary Value ",
    balanceAndOrder: "Balance and Orders",
    start: "Start ",
    stop: "Stop ",
    task: "Task",
    current: "Currently ",
    info: " Information",
    balance: " Balance",
    normal: "Normal",
    notRechage: "Not recharged",
    remarks: "Remarks ",
    creation: "Create ",
    operation: "Operation",
    noEmail: "The account has not added an auxiliary email on the system",
    creat: "Create ",
    edit: "Edit ",
    viewOrder: "View Order ",
    viewPwd: "View password ",
    login: "Login",
    title: " Title",
    template: " Template ",
    type: " type",
    host: "Host",
    handle: "Handle",
    name: "Name ",
    address: " Address",
    isAddress: "Is it the default address",
    whether: "Whether or not ",
    has: "has ",
    yes: "yes",
    no: "no",
    sufficient: "sufficient ",
    update: "Update ",
    delete: "Delete ",
    updater: "Update Person",
    time: " Time",
    total1: "Co",
    item: "Article",
    page: "Page ",
    state: "State",
    city: "City",
    district: "District",
    postalCode: "Postal Code ",
    lastName: "Last Name",
    firstName: "First Name",
    mobile: "Mobile phone number",
    purchase: " Procurement ",
    purchasing: "In the process of procurement",
    waitPurchase: "To be purchased",
    endPurchase: "End purchase ",
    unused: "Proxy not used",
    use: "Use ",
    agent: "Proxy",
    restart: "Restart ",
    pause: "Pause",
    end: "End",
    mainBackend: "The 'big backend",
    purchased: "Purchased",
    error: " Error",
    orderPlacer: "Single player",
    oneMonth: "In January",
    twoMonth: "In February",
    threeMonth: "March ",
    fourMonth: "April ",
    fiveMonth: "May ",
    sixMonth: "June ",
    sevenMonth: "July",
    eightMonth: "In August",
    nineMonth: "September ",
    tenMonth: "October ",
    month1: "In November",
    month2: "December ",
    year: "Year",
    operatingData: "Business data",
    rechargeAmount: "Recharge amount ",
    purchaseCost: "Purchased cost of paid products",
    otherCost: "Other costs of paid products",
    paidAmount: "Collection amount ",
    money: " Money",
    productProfit: "Profit from paid products",
    purchaseManage: "Procurement Batch Management",
    receivedStore: "Already in stock",
    unitCost: "The cost of a single product",
    receiving: "Receipt ",
    allReceived: "Have all items been stored in the warehouse",
    store: " Storage ",
    details: "Details ",
    noData: "No data available at the moment",
    brand: "Brand ",
    hasStock: "Is there inventory available",
    view: "View ",
    delivery: "Delivery",
    outGoging: "Transfer out",
    inComing: "Transfer in",
    equivalentValue: "Equivalent",
    appoint: "appoint",
    storage: " Storage ",
    summary: "Summary",
    inventory: "Inventory",
    totalPrice: "Total price",
    unitPrice: "Unit price",
    equalPrice: "Equal price",
    discount: "Discount",
    averageInventory: "Average inventory",
    inTransitInventory: "In transit ",
    others: "Other ",
    inbound: "Storage ",
    original: "Original",
    final: "In the end",
    average: "On average",
    sold: "Sold",
    transferOut: "Transfer out",
    external: "External ",
    sales: "Sell ",
    profit: " Profit",
    price: " Price",
    amount: "Total Amount ",
    receivable: "Pending Payment ",
    received: "Payment Received ",
    notComplete: "Incomplete ",
    payment: "Collection",
    commision: "Commission",
    outbound: "Out of warehouse",
    salesStaff: "Sales staff",
    payComplete: "has the payment been completed",
    complete: "Complete ",
    sellingPrice: " Price",
    returnPay: " Collection ",
    returnComplete: "Payment collection completed",
    ifreturnComplete:
      "Is it confirmed that the current total amount of outstanding payments has been collected",
    confirmReturn: "Confirm the completion of payment collection",
    additional: "Add ",
    currency: "Currency ",
    corresponding: "Corresponding",
    column: " Column",
    bill: " Bill",
    bookkeeping: " Bookkeeping",
    way: " way",
    estimate: "estimate ",
    modify: "modify ",
    recovery: "recovery ",
    listing: " listing",
    delisting: "delisting ",
    standards: " standards",
    newGoods: "New product",
    growthPercentage: "Growth percentage",
    as: "as ",
    default: "Default ",
    system: " System ",
    customer: " customer",
    contact: " contact",
    company: " company",
    unable: "unable",
    transient: " transient",
    locked: " locked",
    timeout: "timeout ",
    sync: " synchronization ",
    manual: "Manual ",
    new: "New ",
    upload: "upload ",
    file: " file",
    afresh: "afresh ",
    checkWarning: "Some content is incomplete, please check",
    statement: " statement",
    plateform: " plateform",
    claim: "Claim ",
    binance: " binance",
    virtual: "virtual ",
    area: "Area",
    image: "Image",
    close: "close ",
    save: "save ",
    and: " and ",
    canOnly: "can only ",
    fileContent: "Only one file can be selected, reselect to overwrite the previous file!",
    thisTime: "this time ",
    remaining: "Remaining ",
    increase: "Increase ",
    expenses: "expenses ",
    single: "Single ",
    already: "Already ",
    outbound1: "outbound ",
    selected: "selected ",
    list: " list",
    staff: " staff",
    get: "get ",
    lessThan: "mast less than",
    pwdError: "Password must include uppercase and lowercase letters and numbers, with a length between 8 and 15 digits",
    tryLater: "try again later.",
    items: " items",
    import: "import ",
    direct: "direct ",
    youwill: "You will ",
    specifics: "specifics ",
    num: " number",
    aswell: "as well as ",
    tempImport:  "You can choose to download the template below and fill in your account and password for easy bulk import",
    assistant: "Assistant ",
    exchangeRate: "Exchange rate",
    logining: "In login",
    retry: "retry ",
    scope: " scope ",
    press: "According to ",
    user: "user",
    condition: " condition",
    revoke: "Are you sure you want to revoke this ",
    restartErr:  "Cannot restart after completion",
    addOne: "Add at least one",
    noOver: 'Cannot exceed 100',
    accountRecharge: "These accounts are currently being recharged",
    riskControl: 'Already under risk control',
    chooseMore: "Please select at least one of the address templates below",
    return: "Return ",
    last: "Last ",
    next: "Next ",
    step: "Step",
    result: "result",
    noDelete: "The last line is up, it can't be deleted anymore",
    detail: "The description or",
    submit: 'Submit',
    entity:  'Physical object',
    cancelOrder:  'Cancellation',
    bind: ' Merge ',
    daxinBank:  "Da Xin Bank",
    start1: "Starting ",
    diff: "Inconsistent ",
    de: "of ",
    create: "Create ",
    onOver: "Cannot exceed ",
    delInfo: "Are you sure to delete this?",
  },
};
