// 中文语言包：\src\i18n\lang\tr-cn.ts
export default {
  welcome: {
    name: "欢迎",
  },
  title: {
    name: "全球游戏销售管理",
  },
  menu: {
    1000: "图片管理",
    1001: "产品管理",
    1002: "库存管理",
    1003: "价格管理",
    1004: "雷蛇购买",
    1005: "雷蛇充值",
    1006: "数据对账",
    1007: "识图任务",
    1008: "识图结果",
    1009: "产品列表",
    1010: "产品推荐",
    1011: "库存列表",
    1012: "库存明细",
    1013: "价格列表",
    1014: "改价记录",
    1015: "账户管理",
    1016: "购买任务",
    1017: "购买记录",
    1018: "充值商",
    1019: "钱包明细",
    1020: "充值任务",
    1021: "充值账户",
    1022: "充值码",
    1023: "对账统计",
    1024: "系统工具",
    1025: "汇率转换",
    1026: "配置管理",
    1027: "采购管理",
    1028: "供应商管理",
    1029: "关联关系管理",
    1030: "内部货物管理",
    1031: "采购日志",
    1032: "采购订单",
    1033: "记账栏目",
    1034: "记账流水",
    1035: "账单快照",
    1036: "账单报表",
  },
  platformType: {
    1: "雷蛇平台",
    2: "批发平台",
    3: "c2c平台",
    4: "大后台",
  },
  userInfo: {
    myWorkOrder: {
      feedback: "我的反馈",
      creatOrder: "创建工单",
      accountIssue: "账号问题",
      digitalProductIssue: "数字产品问题",
      SelfOperatedProductIssue: "自运营产品问题",
      C2CRechargeIssue: "c2c充值问题",
      OtherIssues: "其他问题",
      Unread: "未读",
      Processing: "处理中",
      shelve: "搁置",
      Completed: "处理完成",
      rejectHandle: "拒绝处理",
      Filter: "筛选器",
      TicketType: "工单类型",
      Select: "请选择",
      TicketStatus: "工单状态",
      createTime: "创建时间",
      ContactCustomerService: "联系客服",
      CustomerService: "客服",
      AverageResponseTime: "一般回复时长",
      WorkingDays: "个工作日",
      EndConversation: "结束会话",
      workOrder: "工单",
      ProductName: "产品名称",
      Order: "订单",
      OrderNumber: "订单号",
      EmailAccount: "邮箱账号",
      ProblemDescription: "问题描述",
      placeholder: "请尽可能详细描述您所遇到的问题",
      description: "提供附件可以更好的诠释您的问题（最多可上传5个文件）",
      PleaseInput: "请输入",
      upload: "上传文件",
      startTime: "开始时间",
      endTime: "结束时间",
      customName: "客户名称",
    },
  },
  common: {
    batch: "批次",
    lotSize: "批量",
    download: "下载",
    please: "请",
    input: "输入",
    choose: "选择",
    email: "邮箱",
    sendEmail: "我们需要将订单发送至该",
    valid: "有效",
    gameUp: "游戏直充",
    manage: "管理",
    history: "历史",
    order: "订单",
    productName: "商品名称",
    account: "账号",
    number: "号",
    tracking: "物流",
    note: "单",
    status: "状态",
    purchaseBatch: "采购批次",
    warehouse: "仓库",
    date: "日期",
    searchResult: "当前筛选条件下的产品总数量为",
    allChoose: "全选",
    batchConfirmReceipt: "批量确认收货",
    product: "产品",
    createBy: "创建人",
    quantity: "数量",
    delivered: "已送达",
    waitProcess: "待处理",
    trackShipInfo: "跟踪运单信息",
    confirmReceipt: "确认收货",
    total: "总",
    cost: "成本",
    confimProducts: "您将确认收货 n 个数量的产品",
    confirm: "确认",
    cancel: "取消",
    noShipInfo: "暂无运送信息",
    shipped: "已发货",
    inTransit: "正在中转",
    transferring: "转运中",
    transfer: "转运",
    deliverying: "正在派送",
    cancelled: "已取消",
    returnProcess: "正在退货",
    sendBack: "准备送回",
    sending: "送回中",
    sended: "已送回",
    today: "今日",
    tomorrow: "明日",
    expected: "预计",
    deliver: "交付",
    giftDispatched: "礼品已送出",
    completed: "已完成",
    refunded: "已退款",
    unknownStatus: "未知状态",
    giftRedeemed: "礼品已兑换",
    noDelivery: "未送达",
    management: "管理",
    region: "地区",
    refresh: "刷新",
    noNeed: "无需",
    wait: "等待",
    refreshing: "刷新中",
    success: "成功",
    fail: "失败",
    recharge: "充值",
    noRecharge: "未充值",
    recharging: "充值中",
    startTime: "开始时间",
    endTime: "结束时间",
    password: "密码",
    pwdReset: "密码重置",
    resetPwd: "重置密码",
    resettingPwd: "重置密码中",
    balanceMinimum: "余额最小值",
    balanceMaximum: "余额最大值",
    minimum: "最小",
    maximum: "最大",
    purchaseG: "购买",
    goods: "商品",
    haocai: "耗材",
    fee: "费用",
    menu1: "耗材费用管理",
    finally: "最后",
    delInfo: "您确定要删除此内容吗?",
    all: "全部",
    games: "游戏",
    totalBalance: "总余额",
    averageRate: "平均汇率",
    noGet: "未获取",
    search: "查询",
    reset: "重置",
    placeOrder: "下单",
    add: "新增",
    export: "导入",
    supplementValue: "补充值",
    balanceAndOrder: "余额和订单",
    start: "启动",
    stop: "停止",
    task: "任务",
    current: "当前",
    info: "信息",
    balance: "余额",
    normal: "正常",
    notRechage: "未充值",
    remarks: "备注",
    creation: "创建",
    operation: "操作",
    noEmail: "账号未在系统上添加辅助邮箱",
    creat: "创建",
    edit: "编辑",
    viewOrder: "查看订单",
    viewPwd: "查看密码",
    login: "登录",
    title: "名称",
    template: "模板",
    type: "类型",
    host: "主机",
    handle: "手柄",
    name: "姓名",
    address: "地址",
    isAddress: "是否为默认地址",
    whether: "是否",
    has: "有",
    yes: "是",
    no: "否",
    sufficient: "足够",
    update: "更新",
    delete: "删除",
    updater: "更新人",
    time: "时间",
    total1: "共",
    item: "条",
    page: "页",
    state: "州",
    city: "市",
    district: "区",
    postalCode: "邮编",
    lastName: "姓",
    firstName: "名",
    mobile: "手机号",
    purchase: "采购",
    purchasing: "采购中",
    waitPurchase: "待采购",
    endPurchase: "结束购买",
    unused: "未使用",
    use: "使用",
    agent: "代理",
    restart: "重启",
    pause: "暂停",
    end: "结束",
    mainBackend: "大后台",
    purchased: "已购",
    error: "错误",
    orderPlacer: "下单人",
    oneMonth: "一月",
    twoMonth: "二月",
    threeMonth: "三月",
    fourMonth: "四月",
    fiveMonth: "五月",
    sixMonth: "六月",
    sevenMonth: "七月",
    eightMonth: "八月",
    nineMonth: "九月",
    tenMonth: "十月",
    month1: "十一月",
    month2: "十二月",
    year: "年",
    operatingData: "经营数据",
    rechargeAmount: "充值金额",
    recharger: "充值商",
    purchaseCost: "已回款产品采购成本",
    otherCost: "已回款产品其它成本",
    paidAmount: "回款",
    money: "金额",
    productProfit: "已回款产品利润",
    purchaseManage: "采购批次管理",
    receivedStore: "已入库",
    unitCost: "单个产品成本",
    receiving: "收货",
    allReceived: "是否全部入库",
    store: "入库",
    details: "明细",
    noData: "暂无数据",
    brand: "品牌",
    hasStock: "是否有库存",
    view: "查看",
    delivery: "送达",
    outGoging: "转出",
    inComing: "转入",
    equivalentValue: "等值",
    appoint: "指定",
    storage: "存放",
    summary: "汇总",
    inventory: "库存",
    totalPrice: "总价",
    unitPrice: "单价",
    equalPrice: "等价",
    discount: "折扣",
    averageInventory: "库存平均",
    inTransitInventory: "在途",
    others: "其他",
    inbound: "入库",
    original: "原",
    final: "最终",
    average: "平均",
    sold: "售出",
    transferOut: "转出",
    external: "外部",
    sales: "出售",
    profit: "利润",
    price: "价格",
    amount: "总额",
    receivable: "待收款",
    received: "已回款",
    notComplete: "未完成",
    payment: "收款",
    commision: "提成",
    outbound: "出仓",
    salesStaff: "销售员工",
    payComplete: "回款是否完成",
    complete: "完成",
    sellingPrice: "售价",
    returnPay: "回款",
    returnComplete: "回款完成",
    ifreturnComplete: "当前待收款总额，确定回款完成吗？",
    confirmReturn: "确定回款完成",
    additional: "追加",
    currency: "币种",
    corresponding: "对应",
    column: "栏目",
    bill: "账单",
    bookkeeping: "记账",
    way: "方式",
    estimate: "预估",
    modify: "修改",
    recovery: "恢复",
    listing: "上架",
    delisting: "下架",
    standards: "规格",
    newGoods: "新品",
    growthPercentage: "增长百分比",
    as: "为",
    default: "默认",
    system: "系统",
    customer: "客户",
    contact: "联系",
    company: "公司",
    unable: "无法",
    transient: "暂时",
    locked: "被锁定",
    timeout: "超时",
    sync: "同步",
    manual: "手动",
    new: "新",
    upload: "上传",
    file: "文件",
    afresh: "重新",
    checkWarning: "有内容未填写完整，请检查",
    statement: "流水",
    plateform: "平台",
    claim: "认领",
    binance: "币安",
    virtual: "虚拟",
    area: "区域",
    image: "图片",
    close: "关闭",
    save: "保存",
    and: "和",
    canOnly: "只能",
    fileContent: "只能选择一个文件,重新选择覆盖上一个文件!",
    thisTime: "本次",
    remaining: "剩余",
    increase: "增加",
    expenses: "费用支出",
    single: "单个",
    already: "已",
    outbound1: "出库",
    selected: "已选",
    list: "列表",
    staff: "员工",
    get: "获取",
    lessThan: "必须小于",
    pwdError: "密码必须包括大小写字母和数字，长度在8到15位之间",
    tryLater: "稍后再试。",
    items: "个",
    import: "导入",
    direct: "直接",
    youwill: "您将",
    specifics: "详情",
    num: "数",
    aswell: "及",
    tempImport: "您可以选择下载下面的模版，然后填写您的账号和密码，以方便您批量导入",
    assistant: "辅助",
    exchangeRate: "汇率",
    logining: "登录中",
    retry: "重试",
    scope: "范围",
    press: "按",
    user: "用户",
    condition: "条件。",
    revoke: "您确定要撤销该",
    restartErr: "结束后无法重启",
    addOne: "至少添加一个",
    noOver: "不能超过100",
    accountRecharge: "这些账号正在充值中：",
    riskControl: "已被风控",
    chooseMore: "请至少选中下面地址模版的其中一个",
    return: "返回",
    last: "上一",
    next: "下一",
    step: "步",
    result: "结果",
    noDelete: "最后一行了，不可以再删了",
    detail: "的描述或",
    submit: "提交",
    entity: "实物",
    cancelOrder: "撤单",
    bind: "合并",
    daxinBank: "大新银行",
    start1: "开始",
    diff: "不一致",
    de: "的",
    create: "创建",
    onOver: "不能超过",
    delInfo: "您确定要删除此内容吗?",
  },
};
