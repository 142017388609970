// 土耳其语言包：\src\i18n\lang\en.ts
export default {
  welcome: {
    name: "Hoşgeldiniz",
  },
  title: {
    name: "Küresel Oyun Satış Yönetimi",
  },
  menu: {
    1000: "Görüntü Yönetimi",
    1001: "Product Management",
    1002: "Inventory Management",
    1003: "Ödül Yönetimi",
    1004: "Razer Purchase",
    1005: "Razer Recharge",
    1006: "Veri Anlaşması",
    1007: "Görüntü tanıma görevi",
    1008: "Görüntü tanıma sonucu",
    1009: "Product List",
    1010: "Product Recommendation",
    1011: "Inventory List",
    1012: "Inventory Details",
    1013: "Ödül Listesi",
    1014: "Fiyat Adjustment Record",
    1015: "Hesap Yönetimi",
    1016: "Pure Task",
    1017: "Pure Record",
    1018: "Recharge Provider",
    1019: "Cüzdanın detayları",
    1020: "Yeniden yükle Görevi",
    1021: "Yeniden yükleme hesabı",
    1022: "Tekrar yükleme Kodu",
    1023: "Anlaşma Statistikleri",
    1024: "Sistem Araçları",
    1025: "Değiştirme oranı değiştirme",
    1026: "Ayarlama Yönetimi",
    1027: "Procurement Management",
    1028: "Supplier Management",
    1029: "Association Relationship Management",
    1030: "İçindeki mal yönetimi",
    1031: "Procurement Log",
    1032: "Alış Emri",
    1033: "Hesap sütunu",
    1034: "hesap ifadeleri",
    1035: "Bill snapshot",
    1036: "Bill Report",
  },
  platformType: {
    1: "雷蛇平台",
    2: "批发平台",
    3: "c2c平台",
    4: "大后台",
  },
  userInfo: {
    myWorkOrder: {
      feedback: "Benim geri dönüşüm",
      creatOrder: "İş emri oluştur",
      accountIssue: "Hesap sorunları",
      digitalProductIssue: "Dijital ürünlerin sorunu",
      SelfOperatedProductIssue: "Kendi özel ürün sorunları",
      C2CRechargeIssue: "C2C Recharge Issue",
      OtherIssues: "Diğer sorunlar",
      Unread: "Okunmamış.",
      Processing: "İşlenme",
      shelve: "Dur bakalım",
      Completed: "İşlenme tamamlandı ",
      rejectHandle: "İzin vermeyi reddediyorum.",
      Filter: "Filter",
      TicketType: "İş sırası tipi",
      Select: "Lütfen seç",
      TicketStatus: "İş Sırası Durumu",
      createTime: "Oluşturma zamanı ",
      ContactCustomerService: "Müşteri hizmeti ile bağlantı edin",
      CustomerService: "Müşteriler hizmeti",
      AverageResponseTime: "Tipik cevap zamanı ",
      WorkingDays: "Bir çalışma günü",
      EndConversation: "Konuşmayı bitir",
      workOrder: "İş emri",
      ProductName: "Produkt İsmi",
      Order: "Emir",
      OrderNumber: "Sıra numarası.",
      EmailAccount: "E-posta hesabı ",
      ProblemDescription: "Sorun tarifi",
      placeholder: "Lütfen karşılaştığınız problemin detaylı bir tasvir verin",
      description:
        "Bağlantıları temin etmek sorunuzu daha iyi açıklayabilir (5 dosya kadar yüklenebilir)",
      PleaseInput: "Lütfen girin",
      upload: "Dosya yükle ",
      startTime: "Başlangıç zamanı.",
      endTime: "Son zamanı ",
      customName: "Müşteriler İsmi",
    },
  },
  common: {
    batch: "Toplu ",
    lotSize: " Batch ",
    download: "İndir ",
    please: "Lütfen ",
    input: "Girdi ",
    choose: "Seçim ",
    email: " E-posta ",
    sendEmail: "Emri buraya göndermeliyiz",
    valid: " Etkileyici",
    gameUp: "Game Direct Charging",
    manage: " Yönetim",
    history: "Tarih ",
    order: " Emir ",
    productName: "Product Name ",
    account: "Hesap ",
    number: "Numara ",
    tracking: "Logistik ",
    note: " Tek ",
    status: " Durum",
    purchaseBatch: "Procurement Batch",
    warehouse: "depo",
    date: "Tarih ",
    searchResult:
      "Şimdiki filtreme kriterilerinin altında toplam ürün sayısı ",
    allChoose: "Her şeyi seç",
    batchConfirmReceipt: "Karşılığın doğrulaması ",
    product: " Product ",
    createBy: "Yaratıcı",
    quantity: "miktarda ",
    delivered: "Delivered",
    waitProcess: "Beklemek",
    trackShipInfo: "Waybill bilgilerini izle",
    confirmReceipt: "Mal almayı onaylayın ",
    total: "Toplam ",
    cost: " mali ",
    confimProducts: "n miktarı ürün almasın ı doğrulayacaksınız",
    confirm: "Onayladı ",
    cancel: "İptal ",
    noShipInfo: "Taşıma Bilgisi Yok",
    shipped: "Gönderildi ",
    inTransit: "Geçiyor",
    transferring: "Taşıma sırasında",
    transfer: "Taşıma ",
    deliverying: "Gönderiliyor",
    cancelled: "İptal edildi",
    returnProcess: "Geri dönüyorum",
    sendBack: "Geri göndermeye hazırlanın",
    sending: "Çin'e döndüm",
    sended: "Geri döndü",
    today: "Bugün",
    tomorrow: "Yarın",
    expected: "beklenen ",
    deliver: "teslimat ",
    giftDispatched: "Hediye gönderildi",
    completed: "Tamamlandı ",
    refunded: "İade edildi ",
    unknownStatus: "Bilinmeyen durum",
    giftRedeemed: "Hediyeler değiştirildi",
    noDelivery: "teslim edilmedi",
    management: "Yönetim",
    region: "Bölge",
    refresh: "Yenileme ",
    noNeed: "Gerekmez ",
    wait: "Bekle ",
    refreshing: "Yenileniyor",
    success: "Başarı",
    fail: "Başarısızlık",
    recharge: "Yeniden değerlendirme",
    noRecharge: "Değerlendirilmemiş",
    recharging: "Yüklemede",
    startTime: "Başlangıç Zamanı",
    endTime: "Son Zamanı",
    password: " Şifre",
    pwdReset: "Parola sıfırlama",
    resetPwd: "Şifreyi sıfırlamak",
    resettingPwd: "Şifreyi sıfırlamak",
    balanceMinimum: "En az denge değeri ",
    balanceMaximum: "En yüksek denge değeri ",
    minimum: "En küçük",
    maximum: "Maksimum",
    purchaseG: "Alın",
    goods: "Product ",
    haocai:  "Kullanılacaklar",
    fee: 'Mali ',
    menu1:  'Kullanılacak mal yönetimi',
    finally: 'Sonunda ',
    delInfo: "Bunu silecek misin?",
    all: "Hepsi ",
    games: "Oyun",
    totalBalance: " Toplam denge",
    averageRate: "Ortalama değişim kursu",
    noGet: "Yapacak değildir",
    search: "Ara",
    reset: "Sıfırla ",
    placeOrder: " Emir" ,
    add: "Yeni ",
    export: "İçeri ",
    supplementValue: "Tamamlama Değeri ",
    balanceAndOrder: "Düzeltme ve düzenleme",
    start: "Başla ",
    stop: "Dur ",
    task: "Görev ",
    current: "Şu anda",
    info: " Bilgi",
    balance: " Düzeltme ",
    normal: "Normal ",
    notRechage: "Yeniden yüklenmedi",
    remarks: "Şarkılar",
    creation: "Oluşturma",
    operation: "Operasyon",
    noEmail: "Hesap sisteme yardımcı bir e-posta eklemedi",
    creat: "Oluşturma ",
    edit: "Düzenle ",
    viewOrder: "Görüntü Düzeni ",
    viewPwd: "Parola bak ",
    login: "Giriş ",
    title: "İsmi ",
    template: " Şablon ",
    type: " Tip",
    host: "Host",
    handle: "Kaldır",
    name: "İsmi ",
    address: " Adres",
    isAddress: "Öntanımlı adres mi?",
    whether: "Doğru mu ",
    has: "ve ",
    yes: "Evet",
    no: "Hayır",
    sufficient: "Yeter ",
    update: "Güncell e",
    delete: "Sil ",
    updater: "Güncelleştir Person",
    time: " Zaman",
    total1: "Co ",
    item: " Makal",
    page: "Page ",
    state: "Eyalet",
    city: "Şehir",
    district: "Bölge",
    postalCode: "Posta Kodu ",
    lastName: "surname",
    firstName: "İsmi ",
    mobile: "Cep telefonu numarası",
    purchase: " İdare ",
    purchasing: "Ödeme sürecinde",
    waitPurchase: "Satın almak için",
    endPurchase: "Satın bitir",
    unused: "Kullanılmadı",
    use: "Kullan ",
    agent: "Proxy",
    restart: "Yeniden başlat ",
    pause: "Dur ",
    end: "Sona ",
    mainBackend: "Büyük arka tarafı",
    purchased: "Yakalanmış",
    error: "Hata",
    orderPlacer: "Tek oyuncu",
    oneMonth: "Ocak'ta",
    twoMonth: "Şubat'ta",
    threeMonth: "Mart",
    fourMonth: "April",
    fiveMonth: "May ıs",
    sixMonth: "June ",
    sevenMonth: "Temmuz",
    eightMonth: "Ağustos'ta",
    nineMonth: "Eylül",
    tenMonth: "Ekim",
    month1: "'Kasım'da",
    month2: "Aralık",
    year: "Yıl",
    operatingData: "İş verileri",
    rechargeAmount: "Yeniden yükle.",
    recharger: "Yeniden yükleyin",
    purchaseCost: "Ödeme ürünlerinin satın alınması",
    otherCost: "Ödeme ürünlerinin diğer maliyetleri",
    paidAmount: " Koleksiyon",
    money: " Amor",
    productProfit: "Ödeme ürünlerinden faydası",
    purchaseManage: "İdare Toplu Yönetimi",
    receivedStore: " Hepsi hazır",
    unitCost: "Tek bir ürün maliyeti",
    receiving: "Receipt ",
    allReceived: "Bütün eşyalar depoda saklanmış mı?",
    store: " depo",
    details: "Ayrıntılar ",
    noData: "Şu anda hiçbir veri yok.",
    brand: " Brand",
    hasStock: "İnşaat makinesi var mı?",
    view: "Görüntüle ",
    delivery: "Delivery",
    outGoging: "Transfer out ",
    inComing: "Transfer in ",
    equivalentValue: "Eğer eşit",
    appoint: "Tasarlanmış",
    storage: " depo ",
    summary: "Toplantı ",
    inventory: "Inventory ",
    totalPrice: "Toplam fiyat ",
    unitPrice: "Birim fiyatı ",
    equalPrice: "Eğer eşit",
    discount: "diskont ",
    averageInventory: "Orta inventör ",
    inTransitInventory: "Yolda ",
    others: "Diğer ",
    inbound: "Depo ",
    original: "Orijinal ",
    final: "Sonunda ",
    average: "Ortamda ",
    sold: "Soldu ",
    transferOut: "Transfer out ",
    external: "Dışarı ",
    sales: "Satın ",
    profit: " Profit",
    price: " Ödül",
    amount: " Toplam miktar",
    receivable: " Bekleyen ödeme",
    received: "Ödeme alındı ",
    notComplete: "Tamamlanmış ",
    payment: " Koleksiyon",
    commision: " Komisyon",
    outbound: "Depodadan çıktım ",
    salesStaff: "Satış personeli ",
    payComplete: "Ödeme tamamlandı mı?",
    complete: "Tamamlandı ",
    sellingPrice: "Ödül ",
    returnPay: "Koleksiyon ",
    returnComplete: "Ödeme koleksiyonu tamamlandı",
    ifreturnComplete:
      "Mevcut ödemelerin toplam miktarı toplandığını doğruladı mı?",
    confirmReturn: "Ödeme koleksiyonunun tamamlamasını onaylayın",
    additional: " Ekle",
    currency: "Para ",
    corresponding: "İtiraf ediyor ",
    column: "Sütün ",
    bill: " Bill",
    bookkeeping: "Kitaplama ",
    way: " Method",
    estimate: " Tahmin et",
    modify: "Değiştir ",
    recovery: "Yedekleme ",
    listing: "Dinliyorum ",
    delisting: "Aşağı indir ",
    standards: "Özellikle ",
    newGoods: "Yeni ürün ",
    growthPercentage: "Yüzde büyüme ",
    as: "yani ",
    default: "Öntanımlı ",
    system: " Sistem ",
    customer: " Müşteriler",
    contact: "Kontakt ",
    company: " Şirket",
    unable: "Yapamaz ",
    transient: "Geçici olarak ",
    locked: "Kilitli ",
    timeout: "Zaman aşımı",
    sync: "Synchronization ",
    manual: "Manual ",
    new: " Yeni",
    upload: "Yükle ",
    file: "Dosya",
    afresh: "Re",
    checkWarning: "Bazı içerikler tamamlanmıyor, lütfen kontrol edin",
    statement: "Çıçan su",
    plateform: " Platform",
    claim: "Anlaşıldı",
    binance: "Binance ",
    virtual: "Sanal ",
    area: " Bölge",
    image: "Resim ",
    close: "Kapat ",
    save: "Kurtar ",
    and: "Ve ",
    canOnly: "Sadece ",
    fileContent:
      "Sadece bir dosya seçilebilir, önceki dosya üstüne yazmak için yeniden seçilebilir",
    thisTime: "Bu sefer ",
    remaining: "Kalıyorum ",
    increase: "Daha fazla ",
    expenses: "Mal harcamaları ",
    single: "Tek ",
    already: "Zaten ",
    outbound1: "Dışarı ",
    selected: "Seçili ",
    list: "Liste ",
    staff: " İşçiler",
    get: "Kesin ",
    lessThan: "Daha az olmalı",
    pwdError:
      "Parola 8 ile 15 rakamlar arasındaki uzunluğu olan büyük ve küçük harfler ve sayıları dahil etmelidir",
    tryLater: "Sonra tekrar dene",
    items: " Bir",
    import: "İçeri ",
    direct: "Tam olarak ",
    youwill: "Öyle olacaksın ",
    specifics: "Ayrıntılar ",
    num: "Numara ",
    aswell: "Ve ",
    tempImport:
      "Şablon aşağıdaki indirmeyi ve hesabınızı ve parolanızı kolay yüklemek için doldurabilirsiniz",
    assistant: " Yardım",
    exchangeRate: " Değiştirme hızı",
    logining: "İçeri girmek için",
    retry: "geri dön ",
    scope: " Çeviri ",
    press: "Bastır ",
    user: " Kullanıcı",
    condition: " Şartlar",
    revoke: "Bunu geri çekmek istediğinden emin misin ",
    restartErr: "Tamamlandıktan sonra yeniden başlatamaz",
    addOne: "En azından bir tane ekle",
    noOver: "100'den fazla olamaz",
    accountRecharge: "Bu hesaplar şu anda yeniden yükleniyor",
    riskControl: "Artık riske kontrol altında",
    chooseMore: "Lütfen aşağıdaki adres şablonlarından en az birini seçin",
    return: "Geri dön ",
    last: "Önceki ",
    next: "Sonraki ",
    step: "Adım",
    result: "Sonuç",
    noDelete: "Son hattı yukarı çıktı, artık silinemez",
    detail: "Tanıma ya da",
    submit: 'Teslim et',
    entity: "Fiziksel nesne",
    cancelOrder: "Geri çekilme",
    bind: ' Birleştir',
    daxinBank: "Yeni Banka",
    start1: "Başlangıç ",
    diff: "Doğrusu",
    de: "of ",
    create: "Oluşturma",
    onOver: "Üstüne geçemez",
    delInfo: "Bunu silecek misin?",
  },
};
